import {baseService} from "../baseService";
import {Login} from "../../model/login/login";
import {Token} from "../../model/login/token";

export class LoginService extends baseService<any> {
    public async Login(item:Login):Promise<Token|null>{
        let view =  await this.Post2<Token>("token/",item,null);
        if(view) {
            let date = new Date();
            date.setTime(date.getTime() + 1 * 60 * 60 * 1000);
            view.expire = date;
        }
        return view;
    }

    public async Refresh(refresh:string):Promise<Token|null>{
        let view =  await this.Post2<Token>("token/refresh/",{refresh:refresh},null);
        return view;
    }
}
