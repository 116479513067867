import React from 'react';
import './App.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {Login} from './components/login'
import {Profile} from './components/profile'
import {Confirm} from './components/confirm'
import {ResultPage} from './components/result'
import {AppState, DefaultAppState, Page} from "./model/appstate";
import {Token} from "./model/login/token";
import {BalanceAccount} from "./model/balance/balance";
import {InvoiceData} from "./model/invoice/invoice";
import {PaymentInfo} from "./model/payment/payment";
import {LoginService} from "./services/login/login";
import {ProfileData} from "./model/profile/profile";

function App() {
  const [app,setApp] = React.useState<AppState>(DefaultAppState);

  React.useEffect(()=>{

    let tokenStr = localStorage.getItem("app-token");
    if(tokenStr) {
      let token:Token = JSON.parse(tokenStr);
      token.expire = new Date(token.expire);
      if(token.expire <= new Date()){
        console.log('refresh')

        new LoginService().Refresh(token.refresh).then(c=>{
          token.access = c?.access??"";

          let date = new Date();
          date.setTime(date.getTime() + 1 * 60 * 60 * 1000);
          token.expire = date;

          localStorage.setItem("app-token",JSON.stringify(token));
          setApp({...app, page: Page.Profile, token: token});
        });//*/
      }else{
        setApp({...app, page: Page.Profile, token: token});
      }
    }
  },[]);



  switch (app.page){
    case Page.Profile:
      return (<Profile token={app.token} invoiceId={getParameterByName("invoice")}
                       GotoConfirm={(c:BalanceAccount,d: InvoiceData,profile:ProfileData)=>{ setApp({...app,page:Page.Confirm,account:c,invoice:d,profile:profile}) }}/>);
    case Page.Confirm:
      return (<Confirm app={app} GotoResult={(c:PaymentInfo)=> { setApp({...app,payment:c,page:Page.Result}) }} />)
    case Page.Result:
      return (<ResultPage app={app} />)
    default:
      return (<Login goToProfile={(token:Token)=>{ localStorage.setItem("app-token",JSON.stringify(token)); setApp({...app,page:Page.Profile,token:token})}} />);
  }

  function getParameterByName(name:string, url = window.location.href):string {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }




}

export default App;
