import React from 'react'
import {Layout, List,Spin,Avatar,Descriptions,Divider,Card,Radio } from 'antd'
import './index.css';
import {Token} from "../../model/login/token";
import {BalanceService} from "../../services/balance/balance";
import {BalanceAccount} from "../../model/balance/balance";
import {InvoiceService} from "../../services/invoice/invoice";
import {InvoiceData} from "../../model/invoice/invoice";
import {DefaultAppState} from "../../model/appstate";
import {ProfileService} from "../../services/profile/profile";
import {ProfileData} from "../../model/profile/profile";

export const Profile=(props:{GotoConfirm:any,token:Token,invoiceId:string})=>{
    const[loading,setLoading] = React.useState<boolean>(true);
    const[list,setList] = React.useState<BalanceAccount[]>([]);
    const[invoice,setInvoice] = React.useState<InvoiceData>(DefaultAppState.invoice);
    const[profile,setProfile] = React.useState<ProfileData>(DefaultAppState.profile);



    React.useEffect(()=>{
        // load data from server
        let pService = new ProfileService();
        pService.GetProfile(props.token.access).then(c=>{
            if(c?.data)
            setProfile(c?.data);
            let service = new BalanceService();
            service.GetBalance(props.token.access).then(c=>{
                if(c?.data)
                {
                    setList(c.data.array_accounts);
                    /*
                    let list=[];
                    list.push(c.data.array_accounts[0]);
                    list.push(c.data.array_accounts[0]);
                    list.push(c.data.array_accounts[0]);
                    list.push(c.data.array_accounts[0]);
                    list.push(c.data.array_accounts[0]);
                    setList(list);//*/
                }




                let iService = new InvoiceService();
                iService.GetInvoice(props.token.access,props.invoiceId).then(c=>{
                    if(c?.data && c.data.length > 0)
                        setInvoice(c.data[0]);
                    setLoading(false);
                })
            });
        })



    },[]);

    return(
        <Spin spinning={loading}>
            <Layout>
               <Layout.Header>
                   <img alt="logo"  src={'logo.png'} />
                   <Avatar>{profile.first_name.substring(0,1)} {profile.last_name.substring(0,1)}</Avatar>
               </Layout.Header>
               <Layout.Content>
                   <Descriptions>
                       <Descriptions.Item label="Order #">{invoice.invoice_number}</Descriptions.Item>
                       <Descriptions.Item label="Total">{invoice.currency} {invoice.amount}</Descriptions.Item>
                   </Descriptions>

                   <Divider></Divider>
                   <Card title="Please select wallet to Pay with :">
                    <div className="site-layout-content">
                       <List
                           itemLayout="vertical"
                           dataSource={list}
                           footer={ <div>
                               <b>Total </b> {list.length} wallets
                           </div>}
                           renderItem={c =>(
                               <List.Item className="walletItem" onClick={()=>{props.GotoConfirm(c,invoice,profile)}}
                               extra={<span>{c.currency} {c.last_balance}</span>}
                               >

                                   <List.Item.Meta
                                       title={<Radio>{c.label}</Radio>}
                                   />

                               </List.Item>
                           )}
                       />
                   </div>
                   </Card>
               </Layout.Content>
           </Layout>
        </Spin>
    )
}


export default Profile;
