import {Avatar, Button, Descriptions, Layout, message} from 'antd'
import './index.css';
import React from "react";
import {PaymentService} from "../../services/payment/payment";
import {AppState} from "../../model/appstate";

export const Confirm=(props:{app:AppState,GotoResult:any})=>{
    const[loading,setLoading] = React.useState<boolean>(false);



    return(
           <Layout>
               <Layout.Header>
                   <img alt="logo"  src={'logo.png'} />
                   <Avatar>{props.app.profile.first_name.substring(0,1)} {props.app.profile.last_name.substring(0,1)}</Avatar>
               </Layout.Header>
            <Layout.Content>
                <Descriptions bordered>
                    <Descriptions.Item label="Order #">{props.app.invoice.invoice_number}</Descriptions.Item>
                    <Descriptions.Item label="Total">{props.app.invoice.currency} {props.app.invoice.amount}</Descriptions.Item>
                    <Descriptions.Item label="Wallet">{props.app.account.label}</Descriptions.Item>
                    <Descriptions.Item label="Wallet Amount">{props.app.account.currency} {props.app.account.last_balance}</Descriptions.Item>
                </Descriptions>
            </Layout.Content>
            <Layout.Footer>
                <Button type="primary" block loading={loading} onClick={()=>{
                    setLoading(true);
                    let service = new PaymentService();
                    service.Pay(
                        props.app.token.access,
                        {
                            account_id: props.app.account.provider_accountid,
                            invoice_id: props.app.invoice.id,
                            amount: props.app.invoice.amount
                        }).then(c=>{
                            if(c?.data?.odoo)
                                props.GotoResult(c?.data);
                            else
                                message.error("Payment fail");

                        setLoading(false);
                    });
                }}>
                    Confirm
                </Button>
            </Layout.Footer>
        </Layout>
    )

}


export default Confirm;
