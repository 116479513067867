import {Token} from "./login/token";
import {BalanceAccount} from "./balance/balance";
import {Invoice, InvoiceData} from "./invoice/invoice";
import {PaymentInfo} from "./payment/payment";
import {ProfileData} from "./profile/profile";

export enum Page{
    Login,
    Profile,
    Confirm,
    Result
}

export interface AppState{
    page:Page,
    token:Token,
    account:BalanceAccount,
    invoice:InvoiceData,
    payment:PaymentInfo,
    profile:ProfileData
}
export const DefaultAppState:AppState={
    page: Page.Login,
    token: {
        detail:"",
        access:"",
        refresh:"",
        expire:new Date()
    },
    account:{
        last_balance:0,
        currency:"",
        dcreated:new Date(),
        dlast_balance:new Date(),
        id:0,
        label:"",
        merchant_id:"",
        provider_accountid:0,
        provider_id:"",
        status:"",
    },
    invoice:{
        id:0,
        amount:0,
        amount_btc:0,
        currency:"",
        ecommerce_number:"",
        email:"",
        first_name:"",
        invoice_number:"",
        last_name:"",
        phone:"",
        status:"",
        status_payment:"",
        type:"",
    },
    payment: {
        odoo: {
            responseUrl: ""
        }
    },
    profile:{
        email:"",
        first_name:"",
        is_active:false,
        last_name:"",
        profile:{
            address_line_1:"",
            address_line_2:"",
            bday:"",
            city:"",
            country:"",
            country_birth:"",
            gender:"",
            middle_name:"",
            nationality:"",
            post_code:"",
            state:"",
        },

    }
}
