import React from 'react'
import {Avatar, Layout, Result} from 'antd'
import './index.css';
import {AppState} from "../../model/appstate";

export const ResultPage=(props:{app:AppState})=>{

    React.useEffect(()=>{
        /*
        let myTimeout = setTimeout(()=>{
            window.location.href=props.app.payment.odoo.responseUrl;
            clearTimeout(myTimeout);
        },5000); // 5 sec
        //*/

        window.self.close();
    },[]);

    return(
        <Layout>
            <Layout.Header>
                <img alt="logo"  src={'logo.png'} />
                <Avatar>{props.app.profile.first_name.substring(0,1)} {props.app.profile.last_name.substring(0,1)}</Avatar>
            </Layout.Header>
            <Layout.Content style={{ padding: '0 50px' }}>
                <div className="site-layout-content">
                    <Result
                        status="success"
                        title={`You Paid ${props.app.invoice.amount} from ${props.app.account.label}`}
                        subTitle={`Order number: ${props.app.invoice.ecommerce_number}, you will redirect in 1 min, please wait.`}
                    />

                </div>
            </Layout.Content>
        </Layout>
    )

}


export default ResultPage;
