import {promises} from "dns";

export  class baseService<T>{
    private baseUrl:string;

    constructor() {
        this.baseUrl="https://expresswalletc2c.services.snbxt.com/api";
    }

    protected async List<T>(url:string):Promise<T[]>{
        let list:T[]=[];
        await fetch(`${this.baseUrl}/${url}`)
            .then(c=>c.json())
            .then(c=> {list = c} )
            .catch(e=> {console.log(e)} );

        return list;
    }

    protected async Get<T>(url:string,token:string):Promise<T|null>{
        let item:T|null=null;

        let requestOptions = {method: 'GET',headers: {"Authorization": `Bearer ${token}`}};
        await fetch(`${this.baseUrl}/${url}`,requestOptions)
            .then(c=>c.json())
            .then(c=> {item = c} )
            .catch(e=> {console.log(e)} );

        return item;
    }

    protected async Post<T>(url:string,data:T):Promise<boolean>{
        let result = false;

        await fetch(`${this.baseUrl}/${url}`,{
            method: 'POST',
            headers: {"Content-Type":"application/json; charset=utf-8"},
            body: JSON.stringify(data)
        })
            .then(c=>c.json())
            .then(c=> {result = true} )
            .catch(e=> {console.log(e)} );

        return result;
    }

    protected async Put<T>(url:string,data:T):Promise<boolean>{
        let result = false;

        await fetch(`${this.baseUrl}/${url}`,{
            method: 'Put',
            headers: {"Content-Type":"application/json; charset=utf-8"},
            body: JSON.stringify(data)
        })
            .then(c=>c.json())
            .then(c=> {result = true} )
            .catch(e=> {console.log(e)} );

        return result;
    }


    protected async Post2<T>(url:string,data:any,token:string|null):Promise<T|null>{
        let result:T|null=null;

        let headers;
        headers ={"Content-Type":"application/json; charset=utf-8"};
        if(token){
            headers ={
                "Content-Type":"application/json; charset=utf-8",
                "Authorization": `Bearer ${token}`
            };
        }
        await fetch(`${this.baseUrl}/${url}`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
            .then(c=>c.json())
            .then(c=> {
                result = c;
            } )
            .catch(e=> {console.log(e)} );

        return result;
    }




}
