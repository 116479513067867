import React from 'react'
import { Button, Row, Input, Form,message,Divider } from 'antd'
import './index.css';
import {LoginService} from "../../services/login/login";


const FormItem = Form.Item
export const Login=(props:{goToProfile:any})=>{

    const[loading,setLoading] = React.useState<boolean>(false);

    const onFinish = (values: any) => {
        console.log('Success:', values);

        setLoading(true);
        let service = new LoginService();
        service.Login(values).then(c=>{
            if(!c){
                message.error("Invalid username or password");
            }else {
                if(c.detail){
                    // error show message
                    message.error(c.detail);
                }
                else {
                    // store token
                    props.goToProfile(c);
                }
            }
            // end loading
            setLoading(false);
        });
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return(
        <div className="content">
            <div className="logo">
                <img alt="logo"  src={'logo.png'} />
                <h3>Pay with e-wallet International </h3>
                <h4>With E-Wallet International, you have the power of VISA, MasterCard and UnionPay, without the need for a bank account. It’s your wallet in the cloud.</h4>
            </div>
            <Form  layout={"vertical"}
                onFinish={onFinish}
            >
                <FormItem name="username" label="Email" rules={[{ required: true }]} hasFeedback>
                    <Input placeholder='Username' size="large"  required/>
                </FormItem>
                <FormItem name="password" label="Password" rules={[{ required: true }]} hasFeedback>
                    <Input type='password' size="large"  placeholder="password" required />
                </FormItem>

                <Row>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Sign in
                    </Button>
                </Row>

                <Divider>Or</Divider>


                <a href="https://ewallet.international/#/signUp" target="_blank">Create New Account</a>
            </Form>
        </div>
    )

}


export default Login;
